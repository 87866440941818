import { createPopper } from '@popperjs/core';

const handler = () => {
  // Toggle dropdown elements using [data-dropdown-toggle]
  document.querySelectorAll('[data-tooltip-target]').forEach((tooltipToggleEl) => {
    const tooltipEl = document.getElementById(tooltipToggleEl.getAttribute('data-tooltip-target'));
    const placement = tooltipToggleEl.getAttribute('data-tooltip-placement') ?? 'top';
    const trigger = tooltipToggleEl.getAttribute('data-tooltip-trigger');

    const popperInstance = createPopper(tooltipToggleEl, tooltipEl, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    const show = () => {
      // Make the tooltip visible
      tooltipEl.classList.remove('opacity-0');
      tooltipEl.classList.add('opacity-100');
      tooltipEl.classList.remove('invisible');
      tooltipEl.classList.add('visible');

      // Enable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: true },
        ],
      }));

      // Update its position
      popperInstance.update();
    }

    const hide = () => {
      // Hide the tooltip
      tooltipEl.classList.remove('opacity-100');
      tooltipEl.classList.add('opacity-0');
      tooltipEl.classList.remove('visible');
      tooltipEl.classList.add('invisible');

      // Disable the event listeners
      popperInstance.setOptions((options) => ({
        ...options,
        modifiers: [
          ...options.modifiers,
          { name: 'eventListeners', enabled: false },
        ],
      }));
    }

    let showEvents = [];
    let hideEvents = [];

    switch (trigger) {
      case 'hover':
        showEvents = ['mouseenter', 'focus'];
        hideEvents = ['mouseleave', 'blur'];
        break;
      case 'click':
        showEvents = ['click', 'focus'];
        hideEvents = ['focusout', 'blur'];
        break;
      default:
        showEvents = ['mouseenter', 'focus'];
        hideEvents = ['mouseleave', 'blur'];
    }

    showEvents.forEach((event) => {
      tooltipToggleEl.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      tooltipToggleEl.addEventListener(event, hide);
    });

  });
}

document.addEventListener('DOMContentLoaded', handler);
document.addEventListener('turbolinks:load', handler);
